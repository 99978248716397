import { css } from "@emotion/core";
import React from "react";
import { rhythm } from "../utils/typography";

const styles = css`
  background-color: #000;
  border-radius: 10px;
  color: #fff;
  font-family: Montserrat, sans-serif;
  font-weight: 400;
  margin-left: -1.3125rem;
  margin-right: -1.3125rem;
  margin-top: ${rhythm(1)};
  padding-left: 1.3125rem;
  padding-right: 1.3125rem;
  p {
    padding-bottom: ${rhythm(0.5)};
    padding-top: ${rhythm(0.5)};
  }
  a.blm {
    color: #fce21b;
  }
  a.eji {
    color: #fff;
  }
  @media (max-width: 672px) {
    border-radius: 0;
  }
`;

function BlackLivesMatter() {
  return (
    <div css={styles}>
      <p>
        <a className="blm" href="https://blacklivesmatter.com/">
          Black Lives Matter
        </a>{" "}
        &mdash;{" "}
        <a
          className="eji"
          href="https://support.eji.org/give/153413/#!/donation/checkout"
        >
          Equal Justice Initiative
        </a>
      </p>
    </div>
  );
}

BlackLivesMatter.defaultProps = {};

BlackLivesMatter.propTypes = {};

export default BlackLivesMatter;
